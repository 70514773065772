import React from "react";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/BrandsTemp";
import Footer from "../../../components/Landers/Footer";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import ThreeSteps from "../../../components/Landers/ThreeSteps";
import WhatsUnique from "../../../components/Landers/WhatsUnique";
import CriminalVerification from "../../../components/Landers/CriminalVerification";
import DataInfo from "../../../components/Data";

const steps = [
  {
    h5: "Request Criminal Verifications",
    p:
      "Initiate the background check process for a candidate for whom you need to run criminal checks.",
  },
  {
    h5: "Scan Court Records",
    p: `Get to know if the candidate has a track record of involvement in criminal activities of any degree.`,
  },
  {
    h5: "Screen the right candidate",
    p:
      "Ensure you know the entire criminal history of the candidate before you onboard them.",
  },
];

const testimonials = [
  {
    name: "Purushottam N",
    company: "Speegile Consulting",
    p: `No doubt SpringVerify has transformed the way we run background checks. 
    I love the variety of checks it offers that delivers accurate results in a quick and efficient manner. `,
  },
  {
    name: "Pushpita G ",
    company: "EZOPS Technologies India Pvt. Ltd.",
    p: `I'm already in love with the UX. It's extremely user-friendly and self-explanatory. All you have to do is to add the candidate and it takes care of the rest. 
    Right from running checks to real-time updates, flags, and reminders - SpringVerify does the job like a breeze. `,
  },
  {
    name: "Rupali M ",
    company: "Integrichain India Pvt. Ltd.",
    p: `The best part about SpringVerify is its user portal. 
    It provides real-time updates making our HR job easier. The entire process is so seamless saving us a lot of time, effort and resources in the process. `,
  },
];

export default () => (
  <div className={styles.criminalRecordCheck}>
    <SEO
      title="Criminal Background Verification Service | SpringVerify India"
      desc="Ensure fair and reliable hiring by confirming candidate's education credentials like education history, duration, training and certifications."
      url="https://in.springverify.com/screenings/criminal-record-check/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/SV-IN+(criminal+record+check).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1={"Comprehensive Criminal \nBackground Verifications"}
        p="Provide a safe working environment for your people to thrive by scrutinizing the candidate’s criminal \nhistory with SpringVerify."
        img="https://assets-sv-in.gumlet.io/LP/criminal-record-check/banner.png"
      />
    </div>
    <Brands data={DataInfo.CRBrandsTemp} />
    <section className={styles.ImportantCBV}>
      <SectionTemplate
        img="criminal-record-check/criminal.svg"
        h2={"Importance of Criminal \nBackground Verifications"}
        p={`Criminal record checks ensure you are well informed about the candidate’s criminal \nhistory if any. It can be critical in protecting your customers, employees, and vendors \nfrom potential liabilities. It includes the following checks:`}
        ul={[
          "Active Crime Monitoring",
          "Sex Offender Registry",
          "Court Record Checks",
          "Domestic and Global Watchlists",
        ]}
      />
    </section>
    <CriminalVerification data={DataInfo.CriminalVerify} />
    <ThreeSteps
      steps={steps}
      subHeader="Easy onboarding"
      candidate={
        "https://assets-sv-in.gumlet.io/LP/criminal-record-check/steps.png"
      }
      path="criminal-record-check/"
    />
    <WhatsUnique
      path="criminal-record-check/"
      info={[
        "Easy onboarding",
        "Safe and secure",
        "Transparent pricing",
        "Workflow automation",
        "Tailored solutions based on your requirements",
        "Powered by AI and Blockchain",
        "Remote-friendly and contactless verifications",
        "Compliant with ISO 27001, PCI, and more",
      ]}
    />
    <Testimonials testimonials={testimonials} path="Employment" />
    <GetStarted
      img="criminal-record-check/getStarted.svg"
      h3={"Ready to get started \nwith us?"}
      p="Screen your candidates now to get insights into their criminal history for a safe and secure workplace"
    />
    <Footer />
  </div>
);
