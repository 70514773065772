import React from "react";
import Styles from "./CriminalVerification.module.scss";

const CriminalVerification = (props) => {
  return (
    <section>
      <div className={Styles.CriminalVWrap}>
        <div className={Styles.CriminalVHeader}>
          <h2>
            How are Criminal Verifications <br />
            performed in SpringVerify?
          </h2>
        </div>
        <div className={Styles.CriminalVCardWrap}>
          {props.data.map((data, index) => {
            return (
              <div className={Styles.CriminalVCard} key={index}>
                <img src={data.path} />
                <h4>{data.header}</h4>
                <p>{data.desc}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CriminalVerification;
